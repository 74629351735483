import { computed, ref } from "vue";
import gpcBlobRepository from "../../repositories/gpcBlobRepository";

const _isUploadingForLanguage = ref(false);
const _isUploadingForPublication = ref(false);
const _isDeleting = ref(false);

export default function useBlob() {
  const isGettingBlobs = ref(false);

  async function uploadAllBlobsAsync() {
    return gpcBlobRepository.uploadAllBlobsAsync();
  }

  async function uploadBlobsAsync(publicationId) {
    _isUploadingForPublication.value = true;
    const response = await gpcBlobRepository.uploadBlobsAsync(publicationId);
    _isUploadingForPublication.value = false;
    return response;
  }

  async function uploadBlobsForLanguageAsync(languageId) {
    _isUploadingForLanguage.value = true;
    const response = await gpcBlobRepository.uploadBlobsForLanguageAsync(languageId);
    _isUploadingForLanguage.value = false;
    return response;
  }

  async function uploadLatestBlobForLanguageAsync(languageId) {
    _isUploadingForLanguage.value = true;
    const response = await gpcBlobRepository.uploadLatestBlobForLanguageAsync(languageId);
    _isUploadingForLanguage.value = false;
    return response;
  }

  async function deleteBlobsAsync(publicationId) {
    _isDeleting.value = true;
    const response = await gpcBlobRepository.deleteBlobsAsync(publicationId);
    _isDeleting.value = false;
    return response;
  }

  async function getBlobsReportAsync() {
    isGettingBlobs.value = true;
    const response = await gpcBlobRepository.getBlobsReportAsync();
    isGettingBlobs.value = false;
    return response;
  }

  async function uploadBlobAsXlsxAsync(publicationId) {
    return gpcBlobRepository.uploadBlobAsXlsxAsync(publicationId);
  }

  async function uploadBlobAsXmlAsync(publicationId) {
    return gpcBlobRepository.uploadBlobAsXmlAsync(publicationId);
  }

  async function uploadBlobsAsJsonAsync(publicationId) {
    return gpcBlobRepository.uploadBlobsAsJsonAsync(publicationId);
  }

  async function uploadBlobsAsZipAsync(publicationId) {
    return gpcBlobRepository.uploadBlobsAsZipAsync(publicationId);
  }

  async function downloadBlobAsXlsxAsync(publicationId) {
    return gpcBlobRepository.downloadBlobAsXlsxAsync(publicationId);
  }

  async function downloadBlobAsXmlAsync(publicationId) {
    return gpcBlobRepository.downloadBlobAsXmlAsync(publicationId);
  }

  async function downloadBlobsAsJsonAsync(publicationId) {
    return gpcBlobRepository.downloadBlobsAsJsonAsync(publicationId);
  }

  async function downloadBlobAsZipAsync(publicationId) {
    return gpcBlobRepository.downloadBlobAsZipAsync(publicationId);
  }

  return {
    isGettingBlobs: computed(() => isGettingBlobs.value),
    isUploadingForLanguage: computed(() => _isUploadingForLanguage.value),
    isUploading: computed(() => _isUploadingForLanguage.value || _isUploadingForPublication.value),
    isDeleting: computed(() => _isDeleting.value),
    uploadAllBlobsAsync,
    uploadBlobsAsync,
    uploadBlobsForLanguageAsync,
    uploadLatestBlobForLanguageAsync,
    deleteBlobsAsync,
    getBlobsReportAsync,
    uploadBlobAsXlsxAsync,
    uploadBlobAsXmlAsync,
    uploadBlobsAsJsonAsync,
    uploadBlobsAsZipAsync,
    downloadBlobAsXlsxAsync,
    downloadBlobAsXmlAsync,
    downloadBlobsAsJsonAsync,
    downloadBlobAsZipAsync,
  };
}
