export function downloadJson(fileName, data) {
  let json = JSON.stringify(data);
  downloadFile(fileName, "application/json", json);
}

export function downloadXml(fileName, data) {
  downloadFile(fileName, "application/xml", data);
}

export function downloadXlsx(fileName, data) {
  downloadFile(fileName, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", data);
}
export function downloadZip(fileName, data) {
  downloadFile(fileName, "application/zip", data);
}

function downloadFile(fileName, contentType, data) {
  const blob = new Blob([data], { type: contentType });
  const link = document.createElement("a");

  link.download = fileName;
  link.href = window.URL.createObjectURL(blob);
  link.dataset.downloadurl = [contentType, link.download, link.href].join(":");

  const evt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  link.dispatchEvent(evt);
  link.remove();
}
