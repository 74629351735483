import { httpCustom, httpGet, getFileName } from "../services/httpService";

export default (function() {
  async function uploadAllBlobsAsync() {
    return httpGet(`/blob/upload/publication/all`);
  }
  async function uploadBlobsAsync(publicationId) {
    return httpGet(`/blob/upload/publication/${publicationId}`);
  }
  async function uploadBlobsForLanguageAsync(languageId) {
    return httpGet(`/blob/upload/publication/language/${languageId}`);
  }
  async function uploadLatestBlobForLanguageAsync(languageId) {
    return httpGet(`/blob/upload/publication/latest/language/${languageId}`);
  }
  async function deleteBlobsAsync(publicationId) {
    return httpGet(`/blob/delete/publication/${publicationId}`);
  }
  async function getBlobsReportAsync() {
    return httpGet(`/blob/publication/report`);
  }
  async function uploadBlobAsXlsxAsync(publicationId) {
    return httpGet(`/blob/upload/publication/${publicationId}/xlsx`);
  }
  async function uploadBlobAsXmlAsync(publicationId) {
    return httpGet(`/blob/upload/publication/${publicationId}/xml`);
  }
  async function uploadBlobsAsJsonAsync(publicationId) {
    return httpGet(`/blob/upload/publication/${publicationId}/json`);
  }
  async function uploadBlobsAsZipAsync(publicationId) {
    return httpGet(`/blob/upload/publication/${publicationId}/zip`);
  }
  async function downloadBlobAsXlsxAsync(publicationId) {
    let response = await httpCustom(`/blob/download/publication/${publicationId}/xlsx`, {
      method: "GET",
      headers: { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    } else {
      let data = await response.blob();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }
  async function downloadBlobAsXmlAsync(publicationId) {
    let response = await httpCustom(`/blob/download/publication/${publicationId}/xml`, {
      method: "GET",
      headers: { "Content-Type": "application/xml;charset=utf-8" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    } else {
      let data = await response.text();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }
  async function downloadBlobsAsJsonAsync(publicationId) {
    let response = await httpCustom(`/blob/download/publication/${publicationId}/json`, {
      method: "GET",
      headers: { "Content-Type": "application/json;charset=utf-8" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    } else {
      let data = await response.json();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }
  async function downloadBlobAsZipAsync(publicationId) {
    let response = await httpCustom(`/blob/download/publication/${publicationId}/zip`, {
      method: "GET",
      headers: { "Content-Type": "application/zip" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    } else {
      let data = await response.blob();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }

  return Object.freeze({
    uploadAllBlobsAsync,
    uploadBlobsAsync,
    uploadBlobsForLanguageAsync,
    uploadLatestBlobForLanguageAsync,
    deleteBlobsAsync,
    getBlobsReportAsync,
    uploadBlobAsXlsxAsync,
    uploadBlobAsXmlAsync,
    uploadBlobsAsJsonAsync,
    uploadBlobsAsZipAsync,
    downloadBlobAsXlsxAsync,
    downloadBlobAsXmlAsync,
    downloadBlobsAsJsonAsync,
    downloadBlobAsZipAsync,
  });
})();
