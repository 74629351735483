<template>
  <div class="row">
    <loader v-if="isGettingBlobs"></loader>
    <panel v-show="!isGettingBlobs" barColor="blue">
      <div class="row align-items-center">
        <div class="col-4 d-flex align-items-start p-1"></div>
        <div class="col-4 align-self-center panel-title p-1">{{ mainTitle }}</div>
        <div class="col-4 d-flex justify-content-end"></div>
        <div class="col-12 text-start d-flex align-items-end">
          <div class="col-3">
            <label for="Language" class="form-label">Language</label>
            <select id="Language" v-model="selectedLanguage" class="form-select">
              <option v-for="(language, index) in selectableLanguages" :key="index" :value="language">
                {{ language.languageName }}
              </option>
            </select>
          </div>
          <div class="col-3 ms-3">
            <label for="Search" class="form-label">Search</label>
            <input id="Search" v-model="search" type="text" class="form-control" placeholder="Search in name..." />
          </div>
          <div class="col-4"></div>
          <div class="col-2">
            <div
              class="button-icon me-3 float-end"
              @click="uploadBlobToAzureForSelectedLanguage"
              title="Upload all browser publication files for selected language"
            >
              <font-awesome v-if="!isUploadingForLanguage" icon="cloud-upload-alt"></font-awesome>
              <font-awesome v-if="isUploadingForLanguage" icon="spinner" class="fa-spinner"></font-awesome>
            </div>
          </div>
        </div>
        <div class="col-12 text-start mt-2">
          <alert v-bind="bindableAlert" class="mt-3 mb-3"></alert>
          <div class="gpc-table table-responsive">
            <table class="table table-hover table-sm" aria-label="Publication blob overview table">
              <thead>
                <tr>
                  <th scope="col" class="col-1 fw-bold">Language Code</th>
                  <th scope="col" class="col fw-bold">File Name</th>
                  <th scope="col" class="col-1 fw-bold">Shown in Browser</th>
                  <th scope="col" class="col-1 fw-bold">Last Modified</th>
                  <th scope="col" class="col-2"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="publication in publicationBlobsToShow"
                  :key="publication.id"
                  :class="{
                    'table-success': publication.isShownInBrowser && publication.isOk,
                    'table-warning': publication.isShownInBrowser && publication.isOutdated,
                    'table-danger': publication.isShownInBrowser && publication.isNotExists,
                  }"
                >
                  <td>{{ publication.languageCode }}</td>
                  <td>{{ publication.publicationName }}</td>
                  <td class="text-center">
                    <input class="form-check-input" type="checkbox" v-model="publication.isShownInBrowser" disabled />
                  </td>
                  <td>{{ publication.lastUpdate ? new Date(publication.lastUpdate).toISOString().slice(0, 10) : "" }}</td>
                  <td>
                    <span class="float-end">
                      <span v-if="!publication.isNotExists" @click="deleteBlobFromAzure(publication)" class="me-3" title="Delete Publication Blob">
                        <font-awesome v-if="!publication.isDeleting" icon="trash-alt" class="hw-20"></font-awesome>
                        <font-awesome v-if="publication.isDeleting" icon="spinner" class="fa-spinner hw-20"></font-awesome>
                      </span>
                      <span
                        v-if="!publication.isNotExists"
                        @click="downloadBlobFromAzure(publication, 'json')"
                        class="me-1"
                        title="Download Publication as .json"
                      >
                        <font-awesome v-if="!publication.isDownloadingJson" icon="file-alt" class="hw-20"></font-awesome>
                        <font-awesome v-if="publication.isDownloadingJson" icon="spinner" class="fa-spinner hw-20"></font-awesome>
                      </span>
                      <span
                        v-if="!publication.isNotExists"
                        @click="downloadBlobFromAzure(publication, 'xml')"
                        class="me-1"
                        title="Download Publication as .xml"
                      >
                        <font-awesome v-if="!publication.isDownloadingXml" icon="file-code" class="hw-20"></font-awesome>
                        <font-awesome v-if="publication.isDownloadingXml" icon="spinner" class="fa-spinner hw-20"></font-awesome>
                      </span>
                      <span
                        v-if="!publication.isNotExists"
                        @click="downloadBlobFromAzure(publication, 'xlsx')"
                        class="me-1"
                        title="Download Publication as .xlsx"
                      >
                        <font-awesome v-if="!publication.isDownloadingXlsx" icon="file-excel" class="hw-20"></font-awesome>
                        <font-awesome v-if="publication.isDownloadingXlsx" icon="spinner" class="fa-spinner hw-20"></font-awesome>
                      </span>
                      <span
                        v-if="!publication.isNotExists"
                        @click="downloadBlobFromAzure(publication, 'zip')"
                        class="me-3"
                        title="Download Publication as .zip"
                      >
                        <font-awesome v-if="!publication.isDownloadingZip" icon="file-archive" class="hw-20"></font-awesome>
                        <font-awesome v-if="publication.isDownloadingZip" icon="spinner" class="fa-spinner hw-20"></font-awesome>
                      </span>
                      <span @click="uploadBlobToAzure(publication)" class="" title="Upload Publication Files">
                        <font-awesome v-if="!publication.isUploading" icon="cloud-upload-alt" class="hw-20"></font-awesome>
                        <font-awesome v-if="publication.isUploading" icon="spinner" class="fa-spinner hw-20"></font-awesome>
                      </span>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </panel>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import Loader from "../components/shared/Loader.vue";
import { computed, onMounted } from "@vue/runtime-core";
import Alert from "../components/shared/Alert.vue";
import Panel from "../components/shared/Panel.vue";
import useAlert from "../composables/shared/useAlert";
import useBlob from "../composables/shared/useBlob";
import useLanguageSingleton from "../composables/language/useLanguageSingleton";
import { downloadJson, downloadXml, downloadXlsx, downloadZip } from "../utils/fileUtil";
import useUserSingleton from "../composables/shared/useUserSingleton";

export default {
  components: { Loader, Alert, Panel },
  setup() {
    const alert = useAlert();
    const {
      getBlobsReportAsync,
      isGettingBlobs,
      uploadBlobsAsync,
      uploadBlobsForLanguageAsync,
      deleteBlobsAsync,
      isDeleting,
      isUploadingForLanguage,
      downloadBlobAsXlsxAsync,
      downloadBlobAsXmlAsync,
      downloadBlobsAsJsonAsync,
      downloadBlobAsZipAsync,
    } = useBlob();
    const { languages } = useLanguageSingleton();
    const { languageId } = useUserSingleton();

    const mainTitle = ref(`Publication Files`);
    const search = ref("");
    const publicationBlobs = ref([]);
    const groupedPublicationBlobs = ref([]);
    const selectableLanguages = ref([...languages.value]);
    const selectedLanguage = ref(selectableLanguages.value.find((f) => f.languageId == languageId.value));

    const sortByLastUpdateDate = (blobs) => blobs.sort((x, y) => y.isShownInBrowser - x.isShownInBrowser);
    const filteredAndSortedPublicationBlobs = computed(() => {
      if (selectedLanguage.value && selectedLanguage.value.languageId != null) {
        return sortByLastUpdateDate(groupedPublicationBlobs.value.filter((blob) => blob.languageId == selectedLanguage.value.languageId));
      }
      return sortByLastUpdateDate(groupedPublicationBlobs.value);
    });
    const publicationBlobsToShow = computed(() => {
      if (search.value) {
        return filteredAndSortedPublicationBlobs.value.filter((blob) => blob.blobName.toLowerCase().includes(search.value.toLowerCase()));
      }
      return filteredAndSortedPublicationBlobs.value;
    });

    const loadBlobsAsync = async () => {
      const response = await getBlobsReportAsync();
      if (response.isSuccess) {
        publicationBlobs.value = response.result;
        response.result.forEach((p) => {
          let existingPublication = groupedPublicationBlobs.value.find((f) => f.publicationId == p.publicationId);
          if (existingPublication != null) {
            if (p.isOutdated) existingPublication.isOutdated = true;
            if (p.isNotExists) existingPublication.isNotExists = true;
          } else {
            groupedPublicationBlobs.value.push(p);
          }
        });
      } else {
        alert.error([response.message]);
      }
    };

    onMounted(() => {
      loadBlobsAsync();
    });

    const uploadBlobToAzure = async (publicationBlob) => {
      if (publicationBlob.isUploading || isUploadingForLanguage.value) return;
      publicationBlob.isUploading = true;

      let response = await uploadBlobsAsync(publicationBlob.publicationId);

      if (response.isSuccess) {
        alert.success([`${publicationBlob.publicationName} uploaded successfully.`]);
        publicationBlob.lastUpdate = new Date().toISOString();
        publicationBlob.isOk = true;
        publicationBlob.isOutdated = false;
        publicationBlob.isNotExists = false;
      } else {
        alert.error([response.message]);
      }
      publicationBlob.isUploading = false;
    };

    const downloadBlobFromAzure = async (publication, type) => {
      let data = null;
      if (type === "xlsx" && !publication.isDownloadingXlsx) {
        publication.isDownloadingXlsx = true;
        data = await downloadBlobAsXlsxAsync(publication.publicationId);
        downloadXlsx(`${publication.publicationName}.xlsx`, data.file);
        publication.isDownloadingXlsx = false;
      }
      if (type === "xml" && !publication.isDownloadingXml) {
        publication.isDownloadingXml = true;
        data = await downloadBlobAsXmlAsync(publication.publicationId);
        downloadXml(`${publication.publicationName}.xml`, data.file);
        publication.isDownloadingXml = false;
      }
      if (type === "json" && !publication.isDownloadingJson) {
        publication.isDownloadingJson = true;
        data = await downloadBlobsAsJsonAsync(publication.publicationId);
        downloadJson(`${publication.publicationName}.json`, data.file);
        publication.isDownloadingJson = false;
      }
      if (type === "zip" && !publication.isDownloadingZip) {
        publication.isDownloadingZip = true;
        data = await downloadBlobAsZipAsync(publication.publicationId);
        downloadZip(`${publication.publicationName}.zip`, data.file);
        publication.isDownloadingZip = false;
      }
    };

    const deleteBlobFromAzure = async (publication) => {
      if (publication.isDeleting) return;
      let confirmed = confirm("Are you sure you want to delete this file from storage?");
      if (!confirmed) return;
      publication.isDeleting = true;
      const response = await deleteBlobsAsync(publication.publicationId);
      if (response.isSuccess) {
        alert.success([`Deleted successfully.`]);
        loadBlobsAsync();
      } else {
        alert.error([response.message]);
      }
      publication.isDeleting = false;
    };

    const uploadBlobToAzureForSelectedLanguage = async () => {
      if (isUploadingForLanguage.value) return;
      let confirmed = confirm("FYI: This background task could take from several minutes till few hours(based on publications count). Continue?");
      if (!confirmed) return;
      publicationBlobsToShow.value.forEach((pb) => (pb.isUploading = true));
      let response = await uploadBlobsForLanguageAsync(selectedLanguage.value.languageId);
      if (response.isSuccess) {
        alert.success([`Uploaded successfully.`]);
        loadBlobsAsync();
      } else {
        alert.error([response.message]);
      }
      publicationBlobsToShow.value.forEach((pb) => (pb.isUploading = false));
    };

    return {
      isGettingBlobs,
      mainTitle,
      search,
      selectableLanguages,
      selectedLanguage,
      publicationBlobsToShow,
      bindableAlert: alert.bindableAlert,
      uploadBlobToAzure,
      uploadBlobToAzureForSelectedLanguage,
      downloadBlobFromAzure,
      deleteBlobFromAzure,
      isUploadingForLanguage,
      isDeleting,
    };
  },
};
</script>
