import { getFileName, httpCustom } from "../services/httpService";

export default (function() {
  async function exportPublishedSchemaAsJsonAsync(publicationId) {
    let response = await httpCustom(`/blob/dynamic/download/publication/${publicationId}/json`, {
      method: "GET",
      headers: { "Content-Type": "application/json;charset=utf-8" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    } else {
      let data = await response.json();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }

  async function exportPublishedSchemaAsXmlAsync(publicationId) {
    let response = await httpCustom(`/blob/dynamic/download/publication/${publicationId}/xml`, {
      method: "GET",
      headers: { "Content-Type": "application/xml;charset=utf-8" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    } else {
      let data = await response.text();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }

  async function exportPublishedSchemaAsXlsxAsync(publicationId) {
    let response = await httpCustom(`/blob/dynamic/download/publication/${publicationId}/xlsx`, {
      method: "GET",
      headers: { "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    } else {
      let data = await response.blob();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }

  async function exportPublishedSchemaAsZipAsync(publicationId) {
    let response = await httpCustom(`/blob/dynamic/download/publication/${publicationId}/zip`, {
      method: "GET",
      headers: { "Content-Type": "application/zip" },
    });
    if (!response.ok) {
      return { isSuccess: false, message: response.statusText };
    } else {
      let data = await response.blob();
      return { isSuccess: true, name: getFileName(response), file: data };
    }
  }

  return Object.freeze({
    exportPublishedSchemaAsJsonAsync,
    exportPublishedSchemaAsXmlAsync,
    exportPublishedSchemaAsXlsxAsync,
    exportPublishedSchemaAsZipAsync,
  });
})();
